import React from 'react';
import Layout from '../Layout';
import SEO from '../SEO';
import styles from './notFound.module.scss';

const text = "This page doesn't exist.";

const NotFound = () => (
  <Layout>
    <SEO title="Page Not Found" is404 />
    <div className={styles.notFoundWrapper}>
      <img
        src="https://res.cloudinary.com/dcppgtrqk/image/upload/s--toC0ZueR--/v1536523955/leaf.png"
        alt=""
      />
      <p>{text}</p>
    </div>
  </Layout>
);

export default NotFound;
